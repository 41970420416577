import React, { Component } from 'react'
import Image from 'react-bootstrap/Image'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Container from 'react-bootstrap/Container'

import MyRevolveApi from '../utils/MyRevolveApi'


function MenuRenderer(props) {
    return (
        <Navbar className="color-nav" variant="dark" fixed="top">
            <Container>
                <Navbar.Brand href="https://hub.revolve.team">
                  <img
                    alt=""
                    src="/wp.png"
                    width="20"
                    height="20"
                  />{' '}
                  Retour au Hub Revolve
                </Navbar.Brand>
                <Navbar.Collapse className="justify-content-end">
                    <NavDropdown title={props.user_name.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())} id="basic-nav-dropdown" style={{ 'fontSize': 'medium' }}>
                        <NavDropdown.Item onClick={props.signOut}>Déconnexion</NavDropdown.Item>
                    </NavDropdown>
                    <Image src={props.user_image} roundedCircle style={{ 'width': '40px' }} />
                </Navbar.Collapse>
            </Container>
        </Navbar>
        )
}

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_info: null,
            error: null
        }
    }
//    componentDidMount() {
//        MyRevolveApi.get_user(this.props.user_id, this.props.jwtToken, (response) => this.setState({ user_info: response }))
//    }
    render() {
        return (
            <MenuRenderer
                user_name={this.state.user_info ? this.state.user_info.name : this.props.user_id.replace('.', ' ')}
                user_image={this.state.user_info ? this.state.user_info.image : MyRevolveApi.get_user_image(this.props.user_id)}
                select_acceuil={this.props.select_acceuil}
                select_profile={this.props.select_profile}
                signOut={this.props.signOut} />
        )
    }
}

export default Menu;
