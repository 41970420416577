import axios from 'axios'

const BASE_URL = 'https://myprofileapi.revolve.team';
const URL_TIMEOUT = 10000;


function requester(jwtToken) {
  return axios.create({
    timeout: URL_TIMEOUT,
    headers: {
      Authorization: jwtToken
    }
  });
}

class MyRevolveApi {
  get_user_image(user_id) {
    var user_string = user_id;
    const words = user_string.split('.');
    if (words.length>1){
      user_string = words[0] + '+' + words[1];
    }

    return `https://ui-avatars.com/api/?name=${user_string}&size=512&background=F2F2F2&color=042D2D&bold=true`
  }

//  get_user(user_id, jwtToken, callback) {
//    requester(jwtToken).get(`${BASE_URL}/v2/users/${user_id}`)
//      .then((response) => callback(response.data))
//      .catch(err => console.log('err: ', err));
//  }
//
  get_user_profile(user_id, jwtToken, callback) {
    requester(jwtToken).get(`${BASE_URL}/v2/users/${user_id}/profile`)
      .then((response) => callback(response.data))
      .catch(err => console.log('err: ', err));
  }

  post_homeworking(user_id, jwtToken, document) {
    return requester(jwtToken).post(`${BASE_URL}/v2/users/${user_id}/homeworking`, document)
  }

  post_address(user_id, jwtToken, document) {
    return requester(jwtToken).post(`${BASE_URL}/v2/users/${user_id}/address`, document)
  }

  post_emergency_contact(user_id, jwtToken, document) {
    return requester(jwtToken).post(`${BASE_URL}/v2/users/${user_id}/emergency_contact`, document)
  }
}

export default new MyRevolveApi();
