import React, { Component, useState } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Alert from 'react-bootstrap/Alert'
import Card from 'react-bootstrap/Card'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import Badge from 'react-bootstrap/Badge'
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import SendingButton from './Misc'
import MyRevolveApi from '../utils/MyRevolveApi'
import { Button } from 'react-bootstrap'

import fr from 'date-fns/locale/fr';
registerLocale('fr', fr)

function Section(props) {
    return (<Accordion defaultActiveKey="0">
        <Accordion.Toggle as={Card.Header} eventKey="0">
            <h2>{props.name} &nbsp; <Badge variant="info">{props.tag}</Badge></h2>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
            <Card.Body>{props.children}</Card.Body>
        </Accordion.Collapse>
    </Accordion>
    )
}

function Property(props) {
    return <dl><dt>{props.type}</dt><dd>{props.value || props.children}</dd></dl>
}

function PublicSection(props) {
    return (
        <Section name="Informations publiques">
            <ul>
                <Property type="Nom" value={props.name} />
                <Property type="Bureau" value={props.office} />
                <Property type="Intitulé du poste" value={props.title} />
            </ul>
        </Section>);
}

function PrivateSection(props) {
    const [showAddress, setShowAddress] = useState(false);
    const handleCloseAddress = () => setShowAddress(false);
    const handleShowAddress = () => setShowAddress(true);
    const [showEmergencyContact, setShowEmergencyContact] = useState(false);
    const handleCloseEmergencyContact = () => setShowEmergencyContact(false);
    const handleShowEmergencyContact = () => setShowEmergencyContact(true);

    let newAddress = {
        "street":  "",
        "postalCode": "",
        "city": ""
    }

    let newEmergencyContact = {
        "input": ""
    }

    const fillNewAddress = (field, value) => {
        newAddress[field] = value
    }

    const fillNewEmergencyContact = (value) => {
        newEmergencyContact["input"] = value
    }

    const [sending, setSending] = useState(false);

    const post_address = () => {
        setSending(true)
         props.updateAddressHandler(newAddress)
        .then(response => { setShowAddress(false); setSending(false) })
        .catch(error => { setShowAddress(false); setSending(false) })
    }

    const post_emergency_contact = () => {
        setSending(true)
         props.updateEmergencyContactHandler(newEmergencyContact)
        .then(response => { setShowEmergencyContact(false); setSending(false) })
        .catch(error => { setShowEmergencyContact(false); setSending(false) })
    }

    return (
        <Section name="Informations privées">
            <ul>
                <Property type="Adresse Postale" value={props.address} />
                <Button variant="primary" onClick={handleShowAddress}>Changement d'adresse</Button>
                <Property type="Date de naissance" value={props.birthdate} />
                <Property type="Contrat" value={props.contract} />
                <Property type="Contact d'urgence" value={props.emergency_contact} />
                <Button variant="primary" onClick={handleShowEmergencyContact}>Changement de contact d'urgence</Button>
            </ul>
            <p><b>Renseigne tes mensurations sur ce <a href="https://docs.google.com/forms/d/e/1FAIpQLSdFmtCUBikQjmk-XBdeG65twGeEebx71tCfG_vON3l0gnSJnQ/viewform?usp=sf_link">Questionnaire Goodies</a></b></p>

            <Modal show={showAddress} onHide={handleCloseAddress}>
                <Modal.Header closeButton>
                    <Modal.Title>Nouvelle adresse</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert variant="warning">
                        Cette fonctionnalité est en cours de validation. Si vous rencontrez des difficultés, merci de le signaler sur <Alert.Link href="https://revolve7.slack.com/archives/CPGU6CB63">#questionsit</Alert.Link>.
                    </Alert>
                    <Form>
                        <Form.Group>
                            <Form.Label>Adresse</Form.Label>
                            <Form.Control placeholder="18 rue des Fleurs" onChange={(e) => fillNewAddress('street', e.target.value)}/>
                            <Form.Label>Code postale</Form.Label>
                            <Form.Control placeholder="31000" onChange={(e) => fillNewAddress('postalCode', e.target.value)}/>
                            <Form.Label>Commune</Form.Label>
                            <Form.Control placeholder="Toulouse" onChange={(e) => fillNewAddress('city', e.target.value)}/>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAddress}>Annuler</Button>
                    <SendingButton variant="primary" sending={sending} onClick={post_address}>Envoyer ma demande</SendingButton>
                </Modal.Footer>
            </Modal>

            <Modal show={showEmergencyContact} onHide={handleCloseEmergencyContact}>
                <Modal.Header closeButton>
                    <Modal.Title>Nouveau contact d'urgence</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert variant="warning">
                        Cette fonctionnalité est en cours de validation. Si vous rencontrez des difficultés, merci de le signaler sur <Alert.Link href="https://revolve7.slack.com/archives/CPGU6CB63">#questionsit</Alert.Link>.
                    </Alert>
                    <Form>
                        <Form.Group>
                            <Form.Label>Contact</Form.Label>
                            <Form.Control placeholder="Prénom NOM 0612345678" onChange={(e) => fillNewEmergencyContact(e.target.value)}/>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEmergencyContact}>Annuler</Button>
                    <SendingButton variant="primary" sending={sending} onClick={post_emergency_contact}>Envoyer ma demande</SendingButton>
                </Modal.Footer>
            </Modal>
        </Section>);
}

function HomeworkingStatus(props) {
    var variant = "secondary"
    if (props.status === "Pending") {
        variant = "warning"
    } else if (props.status === "Rejected") {
        variant = "danger"
    } else if (props.status === "Approved") {
        variant = "success"
    }
    return <Badge variant={variant}>{props.status}</Badge>
}

function HomeWorking(props) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [newSchedule, setNewSchedule] = useState(props.homeworking.schedule)
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date().setMonth(new Date().getMonth()+3));
    const toggleNewSchedule = (day) => {
        if (newSchedule.includes(day)) {
            setNewSchedule(newSchedule.filter((value, index, arr) => { return value !== day }))
        } else {
            setNewSchedule(newSchedule.concat(day))
        }
    }

    const [sending, setSending] = useState(false);

    const post = () => {
        setSending(true)
        props.updateProfileHandler({
            "homeworking": {
                "requests": [{
                    "schedule": newSchedule,
                    "start_date": startDate.toISOString(),
                    "end_date": new Date(endDate).toISOString(),
                    "status": "New"
                }]
            }
        })
            .then(response => { setShow(false); setSending(false) })
            .catch(error => { setShow(false); setSending(false) })
    }

    return (
        <Section name="Télétravail">
            <ul>
                <Property type="Jours de télétravail">
                    {props.homeworking.schedule.join(', ') || "Aucun"}
                </Property>
                <Property type="Demandes en cours">
                    {props.homeworking.requests.length > 0 ? 
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Début</th>
                                <th>Fin</th>
                                <th>Jours</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.homeworking.requests.map((request) => (
                                <tr key={request.id}>
                                    <td>{request.start_date}</td>
                                    <td>{request.end_date}</td>
                                    <td>{request.schedule.join(', ')}</td>
                                    <td><HomeworkingStatus status={request.status} /></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>: ""}
                    {props.homeworking.requests.length === 0 ? "Aucune" : ""}
                </Property>
            </ul>
            <Button variant="primary" onClick={handleShow}>Faire une demande de changement</Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Choix des jours de télétravail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert variant="warning">
                        Cette fonctionnalité est en cours de validation. Si vous rencontrez des difficultés, merci de le signaler sur <Alert.Link href="https://revolve7.slack.com/archives/CPGU6CB63">#questionsit</Alert.Link>.
                    </Alert>
                    <Form>
                        <Form.Group>
                            <Property type="Jours de télétravail">
                                <Form.Check type="checkbox" label="Lundi" checked={newSchedule.includes('Lundi')} onChange={() => toggleNewSchedule('Lundi')} />
                                <Form.Check type="checkbox" label="Mardi" checked={newSchedule.includes('Mardi')} onChange={() => toggleNewSchedule('Mardi')} />
                                <Form.Check type="checkbox" label="Mercredi" checked={newSchedule.includes('Mercredi')} onChange={() => toggleNewSchedule('Mercredi')} />
                                <Form.Check type="checkbox" label="Jeudi" checked={newSchedule.includes('Jeudi')} onChange={() => toggleNewSchedule('Jeudi')} />
                                <Form.Check type="checkbox" label="Vendredi" checked={newSchedule.includes('Vendredi')} onChange={() => toggleNewSchedule('Vendredi')} />
                            </Property>
                            <Property type="Date de début"><DatePicker selected={startDate} onChange={date => setStartDate(date)} /></Property>
                            <Property type="Date de fin"><DatePicker selected={endDate} onChange={date => setEndDate(date)} /></Property>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Annuler</Button>
                    <SendingButton variant="primary" sending={sending} onClick={post}>Envoyer ma demande</SendingButton>
                </Modal.Footer>
            </Modal>
        </Section>
    )
}

function UserProfileRenderer(props) {
    let { profile } = props
    return (
        <div>
            <PublicSection
                name={profile.name}
                office={profile.office}
                title={profile.title}
                bio={profile.bio}
                arrival_date={profile.arrival_date}
                image={profile.image} />
            <PrivateSection
                address={profile.address}
                birthdate={profile.birthdate}
                contract={profile.contract}
                emergency_contact={profile.emergency_contact}
                updateAddressHandler={props.updateAddressHandler}
                updateEmergencyContactHandler={props.updateEmergencyContactHandler} />
            <HomeWorking
                homeworking={profile.homeworking}
                updateProfileHandler={props.updateProfileHandler} />
        </div>)
}

function Error(props) {
    let { error, onClose } = props
    return (
        <div
            style={{
                position: 'fixed',
                top: '74px',
                margin: '1em',
            }}
        >
            <Alert variant="danger" onClose={() => onClose()} dismissible>
                <Alert.Heading>{error.status} - {error.title}</Alert.Heading>
                <p>{error.detail}</p>
            </Alert>
        </div>
    )
}

class UserProfilePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: null,
            error: null
        }
    }
    componentDidMount() {
        MyRevolveApi.get_user_profile(this.props.user_id, this.props.jwtToken, (response) => this.setState({ profile: response }))
    }
    updateProfileHandler = new_profile => {
        return new Promise((resolve, reject) =>
            MyRevolveApi.post_homeworking(this.props.user_id, this.props.jwtToken, new_profile)
                .then(response => {
                    this.setState({ profile: response.data })
                    resolve(response)
                    window.location.reload(false);
                })
                .catch(error => {
                    this.setState({ error: error.response.data })
                    reject(error)
                })
        )
    }
    updateAddressHandler = new_address => {
        return new Promise((resolve, reject) =>
            MyRevolveApi.post_address(this.props.user_id, this.props.jwtToken, new_address)
                .then(response => {
                    window.location.reload(false);
                })
                .catch(error => {
                    this.setState({ error: error.response.data })
                    reject(error)
                })
        )
    }
    updateEmergencyContactHandler = new_emergency_contact => {
        return new Promise((resolve, reject) =>
            MyRevolveApi.post_emergency_contact(this.props.user_id, this.props.jwtToken, new_emergency_contact)
                .then(response => {
                    window.location.reload(false);
                })
                .catch(error => {
                    this.setState({ error: error.response.data })
                    reject(error)
                })
        )
    }
    render() {
        return (
            <div>
                {
                    this.state.error && <Error error={this.state.error} onClose={() => this.setState({ error: null })} />
                }
                {
                    !this.state.profile && <p>Chargement...</p>
                }
                {
                    this.state.profile && <UserProfileRenderer profile={this.state.profile} updateProfileHandler={this.updateProfileHandler} updateAddressHandler={this.updateAddressHandler} updateEmergencyContactHandler={this.updateEmergencyContactHandler} />
                }
            </div>
        )
    }
}

export default UserProfilePage;
